/**
 * Scroll Functions + Handlers
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {

    /*
     * Scroll To Element Button Handlers
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.scroll-to-thee').length > 0) {
        $('.scroll-to-thee').each(function() {
            $(this).on('click', function(e) {
                e.preventDefault();
                var href = $(this).attr('href');
                TalioAjax.scrollToEl(href, 0, null);
            });            
        });        
    }
});