/**
 * Tab Functions + Handlers
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
(function($) {

	/*
	 * Handle Linking To Tabs External URLs Via Hash
	 */
    var url = document.location.toString();
    if (url.match('#')) {
        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
        setTimeout(function() {
            $('.sticky-nav-tabs .sticky-helper').removeAttr('style');
            $('body, html').animate({
                scrollTop: 0
            }, 0);
        }, 300);
        $(window).trigger('resize');
    }

	// ---------------------------------------------------------------------

	/*
	 * Handle Linking To Tabs From Main Menu Hash Links
	 */
    $('#header .navbar-nav > li > .sub-menu li a').on('click', function(e) {
        if ($(this).attr('href').match('#')) {
            $('.nav-tabs a[href="#' + $(this).attr('href').split('#')[1] + '"]').tab('show');
        }
    });

	// ---------------------------------------------------------------------

	/*
	 * Initialise Tabs Default State / View
	 */
    $('.tabs .sub-nav-tabs li:first-child').addClass('active');
    $('.tabs .sub-tab-content .tab-pane:first-child').addClass('in active').find('section').addClass('visible');

	// ---------------------------------------------------------------------

	/*
	 * On Tab Display Reposition Slick Slider(s)
	 */
    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
        $('.slick-slider').slick('setPosition');
        setTimeout(function() {
            //$(window).scroll()
        }, 300)
    });

	// ---------------------------------------------------------------------

	/*
	 * Change Bootstrap 4 Default Tab Handling
	 *
	 * Ensure that the tab li clicked is also set to "active" aswell as the
	 * a link, to enable nested tabs, panels and accordion to work in sync.
	 */
    $('.main-nav-tabs, .nav-steps-wrap').find('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
        $('.tabs .main-nav-tabs, .nav-steps-wrap').find('li.active').removeClass('active');
    	$(e.target).removeClass('active').parent('li').addClass('active');
    });

	// ---------------------------------------------------------------------

	/*
	 * Replicate Handler Above For SubNav Tabs
	 *
	 * ScrollTo animation to affix the subnav
	 * tab bar to the main menu and its offset.
	 */
    $('.sub-nav-tabs').find('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
    	$(this).closest('.sticky-nav-tabs').find('li.active').removeClass('active');
    	$(e.target).parent('li').addClass('active');
        $(e.target.hash).find('section').each(function() {
            $(this).addClass('visible');
        });
        $('html, body').animate({
            scrollTop: $(this).closest('.sticky-nav-tabs').offset().top - 67
        }, 500);
    });

	// ---------------------------------------------------------------------

	/*
	 * @TODO
	 *
	 * Bacon ipsum dolor amet tenderloin cow tongue,
	 * filet mignon kielbasa brisket salami biltong.
	 */
    $('.tab-pane').on('show.bs.collapse', function(e) {
        var off = $(this).offset().top;
        $(this).find('.sub-nav-tabs li:first-child a').tab('show');
        setTimeout(function() {
            $('.slick-slider').slick('setPosition');
            $(window).scroll()
        }, 300);
        $(window).width() <= 800 && setTimeout(function() {
            $('html, body').animate({
                scrollTop: off - 67
            }, 500)
        }, 300)
	});
}
)(jQuery);