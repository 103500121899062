/*
 * Woocommerce Functions + Handlers
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('ul.products li.product').each(function() {
        var t = $(this).find('.woocommerce-loop-product__link').attr('href');
        $(this).find('.add_to_cart_button').text('ORDER').attr('href', t).removeClass('ajax_add_to_cart')
    });


    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('body').hasClass('woocommerce-account')) {
        $('.woocommerce-account-salutation .toggle').on('click', function(e) {
            e.preventDefault();
            $(this).parent().nextAll().toggle();
        });
        $(window).resize(function() {
            var width = window.innerWidth;
            if (width >= 768) {
                $('.woocommerce-account-salutation').nextAll().show();
            } else {
                $('.woocommerce-account-salutation').nextAll().hide();
            }
        });        
    };


    /*
     * Dynamic quantity inputs inserted and wrapped
     * around the existing quantity buttons in place.
     *
     * @param {Object} evt The JQuery event
     */
    $(document).on('click', '.quantity-button', function(evt) {
        var qtyButton = $(this),
            oldValue  = qtyButton.closest('.quantity-wrap').find('.qty').val();
        if (qtyButton.hasClass('increment')) {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
                if (newVal == 0)
                    newVal = 1;
            } else {
                newVal = 1;
            }
        }
        qtyButton.closest('.quantity-wrap').find('.qty').val(newVal);
        $('[name="update_cart"]').prop('disabled', false);
    });


    /*
     * Sets a cookie and hides the store notice
     * when the dismiss button is clicked.
     *
     * @param {Object} evt The JQuery event
     */
    $('.woocommerce-store-notice__dismiss-link').click(function(evt) {
        $('body').removeClass('woocommerce-demo-store');
    });
});
