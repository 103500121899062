/**
 * Pager Functions + Handlers
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
(function($) {
    
    $(document).ready(function() {
        $('.quick-locations > div').show_more(8, 4, '.btn.load-more');
    });

    $.fn.show_more = function(start_count, show_count, button_selector) {
        var $this = $(this);
        $this.hide();
        $this.slice(0, start_count).fadeIn('slow');
        $(button_selector).click(function(e) {
            e.preventDefault();
            var visible = $this.filter(':visible').length;
            $this.slice(visible, visible + show_count).fadeIn('slow');
            if (visible + show_count >= $this.length) {
                $(button_selector).hide();
            }
        });
    };
}
)(jQuery);