/**
 * Slick Carousel Inits + Handles
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.multi_tabs_section_driving_traffic .slider').length > 0) {
        $('.multi_tabs_section_driving_traffic .slider').slick({
            adaptiveHeight: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            centerMode: false,
            arrows: true,
            accessibility: false,
            prevArrow: '<i class="icon-arrow-prev">',
            nextArrow: '<i class="icon-arrow-next">'
        });
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.multi_tabs_section_quotes_1 .quotes-slider').length > 0) {
        $('.multi_tabs_section_quotes_1 .quotes-slider').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            //centerMode: true,
            arrows: true,
            accessibility: false,
            prevArrow: '<i class="icon-arrow-prev">',
            nextArrow: '<i class="icon-arrow-next">',
            responsive: [{
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2
                }
            }, {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    arrows: false
                }
            }]
        });
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.sells').length > 0) {
        var opts = {
            rows: 0,
            infinite: !0,
            slidesToShow: 2,
            slidesToScroll: 2,
            accessibility: false,
            prevArrow: '<button type="button" class="slick-prev btn"><i class="icon icon-arrow-prev"></i></button>',
            nextArrow: '<button type="button" class="slick-next btn"><i class="icon icon-arrow-next"></i></button>',
            responsive: [{
                breakpoint: 1201,
                settings: {
                    arrows: !1,
                    dots: !0
                }
            }, {
                breakpoint: 993,
                settings: {
                    arrows: !0,
                    dots: !1
                }
            }, {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: !1,
                    dots: !0
                }
            }]            
        };
        $('.similar-products-slider').slick(opts);
        $('.suggested-accessories-slider').slick(opts);
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.faqs').length > 0) {
        $('.faqs .items').slick({
            dots: !0,
            infinite: !1,
            prevArrow: '<i class="icon-arrow-prev">',
            nextArrow: '<i class="icon-arrow-next">',
            slidesToShow: 4,
            slidesToScroll: 1,
            accessibility: false,
            arrows: !1,
            responsive: [{
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    dots: !0
                }
            }, {
                breakpoint: 801,
                settings: {
                    slidesToShow: 2
                }
            }, {
                breakpoint: 551,
                settings: {
                    slidesToShow: 1
                }
            }]
        });
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.battery-details').length > 0) {
        $('.battery-details .battery-details-slider').slick({
            dots: !0,
            infinite: !1,
            prevArrow: '<i class="fas fa-chevron-left"></i>',
            nextArrow: '<i class="fas fa-chevron-right"></i>',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: !0,
            adaptiveHeight: false,
            accessibility: false,
            responsive: [{
                breakpoint: 800,
                settings: {
                    arrows: !1
                }
            }]
        });
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.section-photo-slider').length > 0) {
        $('.section-photo-slider').slick({
            dots: !1,
            infinite: !1,
            prevArrow: '<i class="icon-arrow-prev">',
            nextArrow: '<i class="icon-arrow-next">',
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: !0,
            adaptiveHeight: !0,
            accessibility: false,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }]
        });
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.talio-features-slider').length > 0) {
        $('.talio-features-slider').slick({
            dots: false,
            infinite: !0,
            prevArrow: '<i class="fas fa-chevron-left"></i>',
            nextArrow: '<i class="fas fa-chevron-right"></i>',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: !0,
            adaptiveHeight: !0,
            accessibility: false,
            fade: !0,
            responsive: [{
                breakpoint: 800,
                settings: {
                    arrows: !1
                }
            }]
        });
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.talking-points-slider').length > 0) {
        $('.talking-points .talking-points-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: !0,
            dots: !0,
            fade: !0,
            accessibility: false,
            prevArrow: '<i class="icon-arrow-prev">',
            nextArrow: '<i class="icon-arrow-next">',
            responsive: [{
                breakpoint: 1023,
                settings: {
                    arrows: !1
                }
            }]
        })
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.section-tours .tour .tour-gallery').length > 0) {
        $('.section-tours .tour .tour-gallery').each(function() {
            
            var slider = $(this).find('.tour-slider'),
                thumbs = $(this).find('.slider-nav-thumbnails');
            
            slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                infinite: true,
                prevArrow: '<i class="icon-arrow-prev">',
                nextArrow: '<i class="icon-arrow-next">',
                adaptiveHeight: !0,
                asNavFor: thumbs,
                accessibility: false,
                rows: 0
            });

            thumbs.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                asNavFor: slider,
                dots: false,
                infinite: true,
                arrows: false,
                prevArrow: '<i class="icon-arrow-prev">',
                nextArrow: '<i class="icon-arrow-next">',
                focusOnSelect: true,
                accessibility: false,
            });

            // remove active class from all thumbnail slides
            thumbs.find('.slick-slide').removeClass('slick-active');

            // set active class to first thumbnail slides
            thumbs.find('.slick-slide').eq(0).addClass('slick-active');

            // on before slide change match active thumbnail to current slide
            slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                var mySlideNumber = nextSlide;
                thumbs.find('.slick-slide').removeClass('slick-active');
                thumbs.find('.slick-slide').eq(mySlideNumber).addClass('slick-active');
            });

            slider.on('afterChange', function(event, slick, currentSlide) {   
                $('.slide-content').hide();
                $('.slide-content[data-id=' + (currentSlide + 1) + ']').show();
            });
        });
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.single-location .reviews-slider').length > 0) {
        $('.single-location .reviews-slider').slick({
            dots: !1,
            infinite: !1,
            accessibility: false,
            prevArrow: '<i class="icon-arrow-prev">',
            nextArrow: '<i class="icon-arrow-next">',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: !0,
            adaptiveHeight: !0,
            responsive: [{
                breakpoint: 800,
                settings: {
                    dots: !0,
                    arrows: !1
                }
            }]
        });
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.single-product .write-review-icon-carousel').length > 0) {

        var reviewSlider = $('.single-product .write-review-icon-carousel'),
            authorIconId = $('#author-icon-id');
            
        reviewSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            infinite: true,
            prevArrow: '<i class="icon-arrow-prev">',
            nextArrow: '<i class="icon-arrow-next">',
            accessibility: false,
            rows: 0
        });

        reviewSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            var mySlideNumber = nextSlide + 1;
            authorIconId.val(mySlideNumber);
        });

        $('#write-review-modal').on('show.bs.modal', function(e) {
            $('.write-review-icon-carousel').slick('refresh');
        });
    }

    // ---------------------------------------------------------------------

    /*
     * @TODO
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    if ($('.in-the-news .slider').length > 0) {
        $('.in-the-news .slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            accessibility: false,
            prevArrow: '<i class="icon-arrow-prev">',
            nextArrow: '<i class="icon-arrow-next">'
        });
    }

});