/*
 * Init Contact Gmaps
 *
 * Lorem ipsum dolor sit amet, consectetur adipiscing
 * elit. Proin aliquam commodo quam etiamx imperdiet.
 */
window.initContactMap = function initContactMap() {

    // TalioAjax is required to continue
    if (typeof TalioAjax === 'undefined') {
        return false;
    }

    var simple = document.getElementById('map'),
        iconBase = TalioAjax.publicUrl + 'img/content/misc/';

    var style = [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {"color": "#e9e9e9"},
                {"lightness": 17}
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {"color": "#f5f5f5"},
                {"lightness": 20}
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {"color": "#ffffff"},
                {"lightness": 17}
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {"color": "#ffffff"},
                {"lightness": 29},
                {"weight": 0.2}
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {"color": "#ffffff"},
                {"lightness": 18}
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {"color": "#ffffff"},
                {"lightness": 16}
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {"visibility": "off"},
                {"color": "#f5f5f5"},
                {"lightness": 21}
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {"color": "#dedede"},
                {"lightness": 21}
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {"visibility": "on"},
                {"color": "#ffffff"},
                {"lightness": 16}
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {"saturation": 36},
                {"color": "#333333"},
                {"lightness": 40}
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {"visibility": "off"}
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {"color": "#f2f2f2"},
                {"lightness": 19}
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {"color": "#fefefe"},
                {"lightness": 20}
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {"color": "#fefefe"},
                {"lightness": 17},
                {"weight": 1.2}
            ]
        }
    ];

    if (simple != null) {
        
        var lat  = simple.getAttribute('data-latitude'),
            lng  = simple.getAttribute('data-longitude'),
            zoom = simple.getAttribute('data-zoom');
        
        var simplemap = new google.maps.Map(simple, {
                zoom: zoom ? parseFloat(zoom) : 14,
                center: new google.maps.LatLng(lat, lng),
                mapTypeId: 'roadmap',
                disableDefaultUI: false,
                mapTypeControl: false,
                streetViewControl: false,
                scrollwheel: false,
                //styles: style
            });
        
        var pin = new google.maps.Marker({
            position: {lat: parseFloat(lat), lng: parseFloat(lng)},
            map: simplemap,
            //icon: iconBase + 'marker.png'
        });
    }
}

/*
 * Init Location Gmaps
 *
 * Lorem ipsum dolor sit amet, consectetur adipiscing
 * elit. Proin aliquam commodo quam etiamx imperdiet.
 */
window.initLocationMap = function initLocationMap() {

    // TalioAjax is required to continue
    if (typeof TalioAjax === 'undefined') {
        return false;
    }

    var simple = document.getElementById('map'),
        iconBase = TalioAjax.publicUrl + 'img/content/misc/';

    var style = [
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {"color": "#e9e9e9"},
                {"lightness": 17}
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {"color": "#f5f5f5"},
                {"lightness": 20}
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {"color": "#ffffff"},
                {"lightness": 17}
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {"color": "#ffffff"},
                {"lightness": 29},
                {"weight": 0.2}
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {"color": "#ffffff"},
                {"lightness": 18}
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {"color": "#ffffff"},
                {"lightness": 16}
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {"color": "#f5f5f5"},
                {"lightness": 21}
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {"color": "#dedede"},
                {"lightness": 21}
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {"visibility": "on"},
                {"color": "#ffffff"},
                {"lightness": 16}
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {"saturation": 36},
                {"color": "#333333"},
                {"lightness": 40}
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {"visibility": "off"}
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {"color": "#f2f2f2"},
                {"lightness": 19}
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {"color": "#fefefe"},
                {"lightness": 20}
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {"color": "#fefefe"},
                {"lightness": 17},
                {"weight": 1.2}
            ]
        }
    ];

    if (simple != null) {
        
        var lat  = simple.getAttribute('data-latitude'),
            lng  = simple.getAttribute('data-longitude'),
            zoom = simple.getAttribute('data-zoom');
        
        var simplemap = new google.maps.Map(simple, {
                zoom: zoom ? parseFloat(zoom) : 14,
                center: new google.maps.LatLng(lat, lng),
                mapTypeId: 'roadmap',
                disableDefaultUI: false,
                mapTypeControl: false,
                streetViewControl: false,
                scrollwheel: false,
                //styles: style
            });
        
        var pin = new google.maps.Marker({
            position: {lat: parseFloat(lat), lng: parseFloat(lng)},
            map: simplemap,
            //icon: iconBase + 'marker.png'
        });
    }
}
